import React from "react"

/* Base Component */
import ComponentLayout from 'layouts/component.layout'

/* Fontawesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false
library.add(
  faSpinnerThird
)

const LoadingIndicator = () =>  {
  return (
    <ComponentLayout className="grid-flow-row pb-38">
      <div className="text-center col-span-2 md:col-span-6 lg:col-span-12">
        <FontAwesomeIcon size="6x" icon={['fad', 'spinner-third']} spin />
      </div>
    </ComponentLayout>
  )
}

export default LoadingIndicator
