import React, { Component } from 'react'

/* Style */
import * as LinkStyle from './link.module.scss'

/* Fontawesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight } from '@fortawesome/pro-duotone-svg-icons'
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false
library.add(
  faArrowRight
)

class LinkComponent extends Component {
  render () {
    return (
      <span className={`${LinkStyle.bounce}`}>
        <FontAwesomeIcon icon={['fad', 'arrow-right']} />
        <span className="text-lg leading-standard pl-4 inline">{ this.props.text }</span>
      </span>
    )
  }
}

export default LinkComponent
